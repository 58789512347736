<template>
	<article class="view-register">
		<div class="site-wrapper rythm-v-l">
			<header class="page-header">
				<h1>{{ $t("Inscription") }}</h1>
			</header>

			<section class="section--register rythm-v">
				<header>
					<h2>{{ $t("Informations professionnelles") }}</h2>
				</header>

				<div class="form-wrapper card shadow">
					<form ref="formRegister"
								method="post"
								@submit.prevent="registerUser">
						<div class="flex-row flex-center-v">
							<div class="flex-item--30">
								<div class="input-group ">
									<label for="firstname">{{ $t("Prénom") }}</label>
									<input type="text"
												 name="firstname"
												 id="firstname"
												 v-model="user.firstname"
												 disabled />
								</div>
							</div>

							<div class="flex-item--30">
								<div class="input-group ">
									<label for="lastname">{{ $t("Nom") }}</label>
									<input type="text"
												 name="lastname"
												 id="lastname"
												 v-model="user.lastname"
												 disabled />
								</div>
							</div>

							<div class="flex-item--30">
								<div class="input-group">
									<label for="email">{{ $t("E-mail") }}</label>
									<input type="text"
												 name="email"
												 id="email"
												 v-model="user.email"
												 disabled />
								</div>
							</div>
						</div>

						<div class="flex-row">
							<div class="flex-item--30">
								<div class="input-group ">
									<label for="city">{{ $t("Lieu") }}</label>
									<input type="text"
												 name="city"
												 id="city"
												 v-model="user.city"
												 disabled />
								</div>
							</div>

							<div class="flex-item--30">
								<div class="input-group ">
									<label for="zip">{{ $t("Code postal") }}</label>
									<input type="text"
												 name="zip"
												 id="zip"
												 v-model="user.zip"
												 disabled />
								</div>
							</div>

							<div class="flex-item--30 flex-item--grow">
								<div class="input-group ">
									<label for="country">{{ $t("Pays") }}</label>
									<input type="text"
												 name="country"
												 id="country"
												 v-model="user.country"
												 disabled />
								</div>
							</div>

						</div>

						<div class="flex-row">
							<div class="flex-item--30 flex-item--grow">
								<p class="label">{{ $t("Spécialité") }}</p>
								<div class="input-group">
									<select name="specialty"
													id="specialty"
													disabled
													aria-readonly="true">
										<option value="">{{ $t("Aucune sélection") }}</option>
										<option v-for="specialty in lists.specialties"
														:selected="parseInt(user.specialty_id) === specialty.id"
														:key="`specialty_${specialty.id}`"
														:value="specialty.id">{{
															$t(specialty.name)
														}}</option>
									</select>
								</div>
							</div>

							<div class="flex-item--30 flex-item--grow">
								<div class="input-group">
									<label for="lang">{{ $t("Langue") }}</label>
									<input type="text"
												 name="lang"
												 id="lang"
												 :value="$t(user.lang)"
												 disabled />
								</div>
							</div>

							<!-- <div class="flex-item--25 flex-item--grow">
                <p class="label required">{{ $t("Type d’exercice") }}</p>
                <div class="input-group">
                  <select name="practices" id="practices" required @change="setPractice">
                    <option value="">{{ $t("Aucune sélection") }}</option>
                    <option v-for="(practice, index) in lists.practices" :key="`practice_${index}`" :value="practice" :selected="practice === registerData.practice">{{ $t(practice) }}</option>
                  </select>
                </div>
              </div> -->

							<!-- <div class="flex-item--25 flex-item--grow" v-if="this.registerData.practice === 'Autre'">
                <div class="input-group required">
                  <label for="practice_other">{{ $t("Autre type d’exercice") }}</label>
                  <input type="text" name="practice_other" id="practice_other" v-model="registerData.practice_other" required />
                </div>
              </div> -->

							<!-- <div class="flex-item--25 flex-item--grow">
                <p class="label">{{ $t("Catégorie d’âge") }}</p>
                <div class="input-group">
                  <select name="age" id="age" @change="setAge">
                    <option value="">{{ $t("Aucune sélection") }}</option>
                    <option v-for="(age, index) in lists.age" :key="index" :value="age" :selected="age === registerData.age">{{ age }}</option>
                  </select>
                </div>
              </div> -->

							<div class="flex-item--30 flex-item--grow flex-row flex-align-top avatar-wrapper">
								<div class="input-group input-avatar">
									<label for="avatar"
												 :class="validations.avatar.invalid ? 'invalid' : ''">{{ $t("Photo / Avatar") }} <small>{{ validations.avatar.error }}</small></label>
									<input type="file"
												 name="avatar"
												 id="avatar"
												 ref="avatar"
												 accept="image/*"
												 :invalid="validations.avatar.invalid"
												 @change="setAvatarPreview" />
								</div>
								<img v-if="avatarPreviewSrc && !validations.avatar.invalid"
										 :src="avatarPreviewSrc"
										 class="avatar-preview"
										 alt="Avatar"
										 width="128"
										 height="auto" />
							</div>
						</div>

						<div class="flex-row">
							<div class="flex-item">
								<div class="input-group--inline input-group-checkbox">
									<label for="email-consent">
										<input type="checkbox"
													 id="email-consent"
													 name="email-consent"
													 v-model="registerData.email_consent" />
										<span class="caption">{{ $t("J'accepte de recevoir des e-mails de la plateforme (Questions de la semaine, notifications)") }}</span>
									</label>
								</div>
							</div>

							<div class="input-group--controls flex-item--100">
								<button type="submit"
												class="button--primary">
									<svg class="icon icon-check"
											 role="img"
											 xmlns="http://www.w3.org/2000/svg"
											 width="18"
											 height="13"
											 fill="none"
											 viewBox="0 0 18 13">
													                    <path
													                      fill="currentColor"
													                      fill-rule="evenodd"
													                      d="M17.7071 0.292893C18.0976 0.683417 18.0976 1.31658 17.7071 1.70711L6.70711 12.7071C6.31658 13.0976 5.68342 13.0976 5.29289 12.7071L0.292893 7.70711C-0.0976311 7.31658 -0.0976311 6.68342 0.292893 6.29289C0.683417 5.90237 1.31658 5.90237 1.70711 6.29289L6 10.5858L16.2929 0.292893C16.6834 -0.0976311 17.3166 -0.0976311 17.7071 0.292893Z"
													                      clip-rule="evenodd"
													                    />
													                  </svg>
									<span class="caption">{{ $t("Enregistrer") }}</span>
								</button>
							</div>
						</div>
					</form>
				</div>
			</section>
		</div>
	</article>
</template>

<style lang="scss" scoped>
@import "src/scss/register-profile.scss";
</style>

<script>
import countries from "../libs/countries.json";

export default {
	name: "register",

	data: function () {
		return {
			fileInput: null,
			avatarPreviewSrc: "",
			// radio/selects lists
			lists: {
				languages: this.$listLanguages(),
				countries: countries,
				specialties: [],
			},

			validations: {
				avatar: {
					invalid: false,
					error: this.$t("(1 Mo max.)"),
				},
			},

			registerData: {
				avatar: "",
				city: "",
				firstname: "",
				lastname: "",
				country: "FR",
				specialty: "",
			},
		};
	},

	computed: {
		// Current user data
		user: function () {
			return this.$store.state.userData;
		},
	},

	methods: {
		// fetch specialties
		async getSpecialties() {
			return await this.$store.dispatch("GET_SPECIALTIES");
		},

		// set language from select
		setLanguage(e) {
			this.registerData.lang = e.target.value;
		},

		setCountry: function (e) {
			this.registerData.country = e.target.value;
		},

		// setPractice: function(e) {
		//   this.registerData.practice = e.target.value;
		//   if (this.registerData.practice !== "Autre") this.registerData.practice_other = "";
		// },

		// set age select value
		// setAge: function(e) {
		//   this.registerData.age = e.target.value;
		// },

		// avatar preview
		setAvatarPreview: function (e) {
			let fileInput = e.target;
			if (fileInput.files && fileInput.files[0]) {
				this.validations.avatar.invalid = false;
				this.validations.avatar.error = this.$t("(1 Mo max.)");

				// image preview
				let blob = URL.createObjectURL(fileInput.files[0]);
				if (blob) this.avatarPreviewSrc = blob;

				// file type
				if (fileInput.files[0].type.indexOf("image/") === -1) {
					this.validations.avatar.invalid = true;
					this.validations.avatar.error = this.$t("(Le fichier n'est pas une image)");
				}

				// file weight
				if (typeof FileReader !== "undefined") {
					let size = fileInput.files[0].size;
					if (size > 1000000) {
						this.validations.avatar.invalid = true;
					}
				}
			}
		},

		// Create user profile
		registerUser: function () {
			let userID = this.user.id;
			this.registerData.user_id = userID;

			let fileInput = this.$refs.avatar.files[0];
			let formData = new FormData();

			formData.append("file", fileInput);
			formData.append("data", JSON.stringify(this.registerData));

			this.$store
				.dispatch("UPDATE_USER_DATA", { userID, formData })
				.then((user) => {
					// Redirect to dashboard
					this.$router.push("/dashboard");
				})
				.catch((error) => {
					let msg = this.$t("Une erreur est survenue lors de la création de votre profil.<br>Veuillez nous contacter pour résoudre le problème.");

					if (error.status === 409) {
						msg = this.$t("Cette adresse e-mail est déjà utilisée&nbsp;!");
					}

					return this.$toasted.global.appError({
						message: msg,
					});
				});
		},
	},

	async mounted() {
		const is_dev = process.env.NODE_ENV === "development";

		// Fetch specialties
		this.lists.specialties = await this.getSpecialties();
		// Fetch themes

		// Fill available profile data
		this.registerData.email = this.user?.login;
		this.registerData.firstname = this.user?.firstname;
		this.registerData.lastname = this.user?.lastname;
		this.registerData.country = this.user?.country || "FR";
		this.registerData.city = this.user?.city;
		this.registerData.zip = this.user?.zip;
		this.registerData.specialty = this.user?.specialty_id;
		this.registerData.lang = this.user?.lang;
		this.registerData.email_consent = this.user?.email_consent;

		// User not logged in
		if (null === this.user) {
			let successMsg = this.$toasted.global.appSuccess({
				message: this.$t("Votre adresse e-mail est vérifiée !"),
			});
			successMsg.goAway(4000);
			this.$toasted.global.appInfo({
				message: this.$t("Connectez-vous pour compléter votre profil et terminer votre inscription."),
			});
			return this.$router.push("/login");
		}

		// Registration completed already
		else {
			if (parseInt(this.user.registration_complete) === 1 && !is_dev) {
				return this.$router.push("/dashboard");
			}
		}

		this.registerData.user_id = this.user.id;
	},
};
</script>
